import type { Router, LocationQuery } from 'vue-router'

/**
 * All routes should have trailing slashes.
 * If the user requests a route without a trailing slash, AWS will redirect to the same route with a trailing slash.
 */

export const routeMap = {
  dashboard: {
    path: '/',
    label: 'Dashboard',
  },
  burndown: {
    path: '/burndown/',
    label: 'Burndown',
  },

  seeds: {
    path: '/seeds/',
    label: 'Seeds',
  },

  planning: {
    path: '/planning/',
    label: 'Planning',
  },

  stats: {
    path: '/stats/',
    label: 'Stats',
  },

  sandbox: {
    path: '/sandbox/',
    label: 'Sandbox',
  },

  roadmap: {
    path: '/roadmap/',
    label: 'Roadmap',
  },

  about: {
    path: '/about/',
    label: 'About',
  },
} as const

export type RouteName = keyof typeof routeMap

export const guestRoutes: RouteName[] = ['seeds'] // first one is the home!

/**
 * Returns the serialized value of a query string key.
 *
 * NOTES: we express data in QS by (de)serializing it to/from JSON strings, so we expect
 * to always handle and support QS as Record<string, string>.
 * @param router
 * @param key
 * @returns
 */
export const getQsValue = (query: LocationQuery, key: string) => {
  const val = query[key]
  if (val instanceof Array) {
    console.error(
      `unexpected query string val for key ${key}: current logic just supports QS: Record<string, string>, an array cannot be the value`,
      val,
    )
    return undefined
  }
  return val
}
