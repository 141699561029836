import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { createVuetify } from 'vuetify'
import { vuetifyTheme } from './2.vuetify/theme'

type TooltipDirectiveBinding =
  | string
  | {
      text: string
      eager: boolean
      contentProps: Record<string, any>
      // ...other props can be typed from docs (they are not exported by Vuetify)
      // (camelCase only!)
      // https://vuetifyjs.com/en/api/v-tooltip/#props
    }

const TOOLTIP_PROPS_DEFAULTS = {
  eager: false,
  contentProps: { style: 'white-space: pre-wrap' },
}

export default defineNuxtPlugin((nuxt) => {
  // a directive consists of a set of hooks https://vuejs.org/guide/reusability/custom-directives#directive-hooks
  // we want to give Tooltip hooks some default bindings for the whole app
  const Tooltip = mapValues((hook) => {
    if (hook === undefined || hook === true) return hook
    return (...args: Parameters<typeof hook>) => {
      let binding = args[1].value as TooltipDirectiveBinding

      binding =
        typeof binding === 'string'
          ? {
              ...TOOLTIP_PROPS_DEFAULTS,
              text: binding,
            }
          : { ...TOOLTIP_PROPS_DEFAULTS, ...binding }

      return hook(
        args[0],
        {
          ...args[1],
          value: binding,
        },
        args[2],
        args[3],
      )
    }
  }, directives.Tooltip)

  // nuxt.vueApp.directive('tooltip', boundTooltip as any)

  const vuetify = createVuetify({
    ssr: true,
    components,
    directives: { ...omit(directives, 'Tooltip'), Tooltip },
    theme: vuetifyTheme,
  })
  nuxt.vueApp.use(vuetify)
})
