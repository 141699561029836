import { default as about4jzp6jXN5tMeta } from "/builds/kampaay/scrum/pages/about.vue?macro=true";
import { default as burndownRW2r245pj4Meta } from "/builds/kampaay/scrum/pages/burndown.vue?macro=true";
import { default as index0qkFhTKX0NMeta } from "/builds/kampaay/scrum/pages/index.vue?macro=true";
import { default as planningM2UynJ3slJMeta } from "/builds/kampaay/scrum/pages/planning.vue?macro=true";
import { default as roadmapvqBvXGbWMaMeta } from "/builds/kampaay/scrum/pages/roadmap.vue?macro=true";
import { default as sandbox3AKjq3iNRJMeta } from "/builds/kampaay/scrum/pages/sandbox.vue?macro=true";
import { default as seedsgYO5nZ57cWMeta } from "/builds/kampaay/scrum/pages/seeds.vue?macro=true";
import { default as stats9MeRQoLWBFMeta } from "/builds/kampaay/scrum/pages/stats.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("/builds/kampaay/scrum/pages/about.vue")
  },
  {
    name: "burndown",
    path: "/burndown",
    component: () => import("/builds/kampaay/scrum/pages/burndown.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/builds/kampaay/scrum/pages/index.vue")
  },
  {
    name: "planning",
    path: "/planning",
    component: () => import("/builds/kampaay/scrum/pages/planning.vue")
  },
  {
    name: "roadmap",
    path: "/roadmap",
    component: () => import("/builds/kampaay/scrum/pages/roadmap.vue")
  },
  {
    name: "sandbox",
    path: "/sandbox",
    component: () => import("/builds/kampaay/scrum/pages/sandbox.vue")
  },
  {
    name: "seeds",
    path: "/seeds",
    component: () => import("/builds/kampaay/scrum/pages/seeds.vue")
  },
  {
    name: "stats",
    path: "/stats",
    component: () => import("/builds/kampaay/scrum/pages/stats.vue")
  }
]