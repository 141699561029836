import { SpecialIssue, SPRINTS_RESULTS_ISSUE_ID } from './SpecialIssue'

type GLSprintsResults = Record<number, GLSprintResults>

class SprintResults extends SpecialIssue<GLSprintsResults> {
  constructor() {
    super(SPRINTS_RESULTS_ISSUE_ID)
  }
  async load() {
    await super._load()
    return this.data
  }
  save(sprintNumber: number, results: GLSprintResults) {
    return this._save((data) => {
      data[sprintNumber] = results
      return data
    })
  }
  override get data() {
    return super.data
  }
}

export const sprintResults = reactifyClass(SprintResults)()
