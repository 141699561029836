import revive_payload_client_dGvbK9cpOq from "/builds/kampaay/scrum/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@18.19.64_ioredis@5.4.1_magicast@0.3.5_rollup@_p5ugzijmgl2a4yb6ff4niyfgw4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_z6k3X0LN2T from "/builds/kampaay/scrum/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@18.19.64_ioredis@5.4.1_magicast@0.3.5_rollup@_p5ugzijmgl2a4yb6ff4niyfgw4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_bDmR0bH6Sd from "/builds/kampaay/scrum/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@18.19.64_ioredis@5.4.1_magicast@0.3.5_rollup@_p5ugzijmgl2a4yb6ff4niyfgw4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_BQsSHZx61U from "/builds/kampaay/scrum/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@18.19.64_ioredis@5.4.1_magicast@0.3.5_rollup@_p5ugzijmgl2a4yb6ff4niyfgw4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_te5yq6me1M from "/builds/kampaay/scrum/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@18.19.64_ioredis@5.4.1_magicast@0.3.5_rollup@_p5ugzijmgl2a4yb6ff4niyfgw4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_fICOrtJFyt from "/builds/kampaay/scrum/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@18.19.64_ioredis@5.4.1_magicast@0.3.5_rollup@_p5ugzijmgl2a4yb6ff4niyfgw4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_wBOFgsJrqA from "/builds/kampaay/scrum/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@18.19.64_ioredis@5.4.1_magicast@0.3.5_rollup@_p5ugzijmgl2a4yb6ff4niyfgw4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/builds/kampaay/scrum/.nuxt/components.plugin.mjs";
import prefetch_client_zvy88M2Kk0 from "/builds/kampaay/scrum/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@18.19.64_ioredis@5.4.1_magicast@0.3.5_rollup@_p5ugzijmgl2a4yb6ff4niyfgw4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import _1_extensions_DXLq8Vce8i from "/builds/kampaay/scrum/plugins/1.extensions.ts";
import _2_vuetify_Hdwv10Z9Ys from "/builds/kampaay/scrum/plugins/2.vuetify.ts";
import _3_queryString_BGL040dd6b from "/builds/kampaay/scrum/plugins/3.queryString.ts";
export default [
  revive_payload_client_dGvbK9cpOq,
  unhead_z6k3X0LN2T,
  router_bDmR0bH6Sd,
  payload_client_BQsSHZx61U,
  navigation_repaint_client_te5yq6me1M,
  check_outdated_build_client_fICOrtJFyt,
  chunk_reload_client_wBOFgsJrqA,
  components_plugin_KR1HBZs4kY,
  prefetch_client_zvy88M2Kk0,
  _1_extensions_DXLq8Vce8i,
  _2_vuetify_Hdwv10Z9Ys,
  _3_queryString_BGL040dd6b
]